.swiper-pagination-bullet {
    margin-bottom: -10px !important;
    z-index: 4;
}
.white-bullet {
    margin-bottom: -10px !important;
    background-color: white;
    opacity: 1 !important;
    z-index: 4;
}
.swiper-wrapper {
    padding-bottom: 20px;
}