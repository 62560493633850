.swiper-pagination-bullet {
    margin-bottom: -10px !important;
    z-index: 4;
}
.swiper-pagination-bullet-active {
    margin-bottom: -10px !important;
    z-index: 4;
}
.swiper-wrapper {
    padding-bottom: 20px;
}